"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vuex = require("vuex");
var _errorParse = require("@/utils/error-parse");
var _store = require("@/api/store");
var _user = require("@/api/user");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      stores: [],
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        storeId: null,
        search: null,
        role: null,
        active: null
      },
      total: 0
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    this.fetchData();
    (0, _store.getStores)({
      perPage: 999
    }).then(response => {
      this.stores = response.data;
    });
  },
  methods: {
    async fetchData() {
      if (!this.user.roles.find(role => ['Operation Manager', 'Admin'].includes(role.name))) {
        this.listQuery.active = 'true';
      }
      // this.listLoading = true
      return (0, _user.getUsers)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    leaveSummary(id) {
      this.$router.push(`/users/${id}/leaves/summary`);
    },
    setActive(id) {
      return _elementUi.MessageBox.confirm('Confirm to Activate User?', 'Confirm', {
        confirmButtonText: 'Activate',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _user.activateUser)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'User Activated',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    setNotActive(id) {
      return _elementUi.MessageBox.confirm('Confirm to Deactivate User?', 'Confirm', {
        confirmButtonText: 'Deactivate',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _user.deactivateUser)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'User Deactivated',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    deleteUser(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete user?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _user.deleteUser)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'User deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }
  }
};
exports.default = _default;