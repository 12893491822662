"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _store = require("@/api/store");
var _expense = require("@/api/expense");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      StoreExpense: {
        id: null,
        uuid: null,
        store: {
          id: null,
          name: null
        },
        expense: {
          id: null,
          name: null,
          uuid: null
        },
        expense_Id: null,
        amount: null
      },
      disableSelectExpense: false,
      expenses: null,
      buttonText: null
    };
  },
  async created() {
    this.getExpensesData();
    if (this.$route.params.sExpenseId) {
      this.disableSelectExpense = true;
      this.buttonText = _i18n.default.t('StoreExpense.Edit');
      this.fetchData();
    } else {
      this.buttonText = _i18n.default.t('StoreExpense.Add');
      this.getStoreData();
    }
  },
  methods: {
    async getExpensesData() {
      this.loading = true;
      (0, _expense.getExpenses)({
        limit: 999
      }).then(response => {
        this.expenses = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    async fetchData() {
      this.loading = true;
      (0, _store.getStoreExpense)(this.$route.params.sExpenseId).then(response => {
        this.StoreExpense = response.data;
        this.StoreExpense.expense_Id = response.data.expense.id;
      }).finally(() => {
        this.loading = false;
      });
    },
    async getStoreData() {
      this.loading = true;
      (0, _store.getStore)(this.$route.params.id).then(response => {
        this.StoreExpense.store = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        if (this.$route.params.sExpenseId) {
          (0, _store.editStoreExpense)(this.StoreExpense).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('StoreExpense.StoreExpenseupdated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/stores/${this.$route.params.id}/expenses`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('StoreExpense.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            // console.log(err)
            this.loading = false;
          });
        } else {
          (0, _store.createStoreExpense)(this.StoreExpense).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('StoreExpense.StoreExpensecreated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/stores/${this.$route.params.id}/expenses`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('StoreExpense.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;