"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _user = require("@/api/user");
var _shift = require("@/api/shift");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      minTime: '00:00',
      maxTime: '23:55',
      step: '00:05',
      shift: {
        id: null,
        name: '',
        fromTime: null,
        toTime: null,
        roleId: null
      },
      roles: [{
        name: null,
        id: null
      }],
      buttonText: 'Add',
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        fromTime: {
          required: true,
          message: 'Start time is required'
        },
        toTime: {
          required: true,
          message: 'End time is required'
        },
        roleId: {
          required: true,
          message: 'Role is required'
        }
      },
      isEdit: false
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    }
  },
  async created() {
    (0, _user.getRoles)({
      dutyRoleOnly: 1
    }).then(response => {
      this.roles = response.data;
    });
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _shift.getShift)(this.$route.params.id).then(response => {
        response.data.fromTime = '' + response.data.from_hour + ':' + (response.data.from_min > 9 ? '' : '0') + response.data.from_min;
        response.data.toTime = '' + response.data.to_hour + ':' + (response.data.to_min > 9 ? '' : '0') + response.data.to_min;
        this.shift = response.data;
        this.shift.roleId = response.data.role.id;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          const start = this.shift.fromTime.split(':');
          const end = this.shift.toTime.split(':');
          const param = {
            fromHour: start[0],
            fromMin: start[1],
            toHour: end[0],
            toMin: end[1],
            name: this.shift.name,
            roleId: this.shift.roleId
          };
          this.loading = true;
          if (this.$route.params.id) {
            param.id = this.$route.params.id;
            (0, _shift.editShift)(param).then(() => {
              (0, _elementUi.Message)({
                message: 'Shift updated.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/shifts`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
            }).finally(() => {
              this.loading = false;
            });
          } else {
            (0, _shift.createShift)(param).then(() => {
              (0, _elementUi.Message)({
                message: 'Shift created.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/shifts`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
            }).finally(() => {
              this.loading = false;
            });
          }
        }
      });
    }
  }
};
exports.default = _default;