"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _vuex = require("vuex");
var _user = require("@/api/user");
var _store = require("@/api/store");
var _leave = require("@/api/leave");
var _router = require("@/router");
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default
  },
  data() {
    const checkConfirmPassword = (rule, value, callback) => {
      if (this.user.password !== '') {
        if (value === '') {
          callback(new Error('Please confirm the password'));
        } else {
          if (value !== this.user.password) {
            callback(new Error('Password mismatch'));
          }
          callback();
        }
      }
    };
    const validateSalary = (rule, value, callback) => {
      let invalid = false;
      if (this.user && JSON.parse(JSON.stringify(this.user.roles)).indexOf(4) !== -1) {
        if (isNaN(value)) {
          invalid = true;
          callback(new Error('Invalid Monthly Salary'));
        } else if (parseFloat(value) <= 0) {
          invalid = true;
          callback(new Error('Monthly Salary is required'));
        } else if (parseFloat(value) <= 1) {
          invalid = true;
          callback(new Error('Invalid Monthly Salary'));
        }
      }
      if (!invalid) {
        callback();
      }
    };
    const validateWorkingHours = (rule, value, callback) => {
      let invalid = false;
      if (this.user && JSON.parse(JSON.stringify(this.user.roles)).indexOf(4) !== -1) {
        if (isNaN(value)) {
          invalid = true;
          callback(new Error('Invalid Working Hours Per Day'));
        } else if (parseFloat(value) < 0.5) {
          invalid = true;
          callback(new Error('Working Hours is required'));
        }
      }
      if (!invalid) {
        callback();
      }
    };
    const validateWage = (rule, value, callback) => {
      let invalid = false;
      if (this.user && JSON.parse(JSON.stringify(this.user.roles)).indexOf(9) !== -1) {
        if (isNaN(value)) {
          invalid = true;
          callback(new Error('Invalid hourly Wage'));
        } else if (parseFloat(value) <= 0) {
          invalid = true;
          callback(new Error('Hourly Wage is required'));
        }
      }
      if (!invalid) {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      let invalid = false;
      if (!this.user.uuid && JSON.parse(JSON.stringify(this.user.roles)).find(role => [4].includes(role))) {
        if (!value) {
          invalid = true;
          // console.log('empty email')
          callback(new Error('Email is required for reset password'));
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          invalid = true;
          // console.log('invalid email')
          callback(new Error('Invalid Email'));
        }
      }
      if (!invalid) {
        callback();
      }
    };
    return {
      loading: false,
      tenants: [],
      user: {
        id: null,
        name: '',
        email: '',
        tel: '',
        hkid: '',
        roles: [],
        stores: [],
        leaves: [],
        password: '',
        password_confirmation: '',
        attachmentArrayId: [],
        userRoles: [],
        maxRoleLevel: 0,
        hourlyWage: null,
        monthlySalary: null,
        workingHoursPerDay: null,
        joiningDate: '',
        headOffice: 0,
        tenant: {
          uuid: null
        }
      },
      roles: [],
      stores: [],
      leaves: [],
      buttonText: 'Add',
      hrPerMonth: 0,
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        monthlySalary: {
          validator: validateSalary,
          trigger: 'blur'
        },
        workingHoursPerDay: {
          validator: validateWorkingHours,
          trigger: 'change'
        },
        hourlyWage: {
          validator: validateWage,
          trigger: 'blur'
        },
        roles: {
          required: true,
          message: 'Role is required',
          trigger: 'change'
        },
        password_confirmation: {
          validator: checkConfirmPassword,
          trigger: 'blur'
        },
        email: {
          validator: validateEmail,
          trigger: 'blur'
        }
      },
      headOffice: [{
        name: 'No',
        value: 0
      }, {
        name: 'Yes',
        value: 1
      }],
      isEdit: false,
      fileList: [],
      uploadDomain: process.env.VUE_APP_BASE_API,
      uploadHeader: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      uploadData: {
        user_id: ''
      },
      asyncRoutes: []
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    ...(0, _vuex.mapGetters)({
      currentUser: 'user'
    })
  },
  watch: {
    immediate: true,
    async handler() {
      await this.$nextTick();
      this.$refs.form.validate();
    }
  },
  async created() {
    for (let r = 0; r < _router.asyncRoutes.length; r++) {
      let role = [];
      if (_router.asyncRoutes[r].path === '/') {
        continue;
      }
      if (_router.asyncRoutes[r].hidden === undefined || _router.asyncRoutes[r].hidden === false) {
        if (_router.asyncRoutes[r].children !== undefined && _router.asyncRoutes[r].children.length === 1) {
          if (_router.asyncRoutes[r].children[0].meta !== undefined && _router.asyncRoutes[r].children[0].meta.roles !== undefined) {
            role = _router.asyncRoutes[r].children[0].meta.roles;
          }
          this.asyncRoutes.push({
            name: _router.asyncRoutes[r].children[0].name,
            role: role
          });
        } else {
          if (_router.asyncRoutes[r].meta !== undefined && _router.asyncRoutes[r].meta.roles !== undefined) {
            role = _router.asyncRoutes[r].meta.roles;
          }
          this.asyncRoutes.push({
            name: _router.asyncRoutes[r].name,
            role: role
          });
        }
      }
    }
    this.loading = true;
    const promises = [];
    promises.push((0, _user.getRoles)().then(roleResponse => {
      this.roles = roleResponse.data;
    }));
    promises.push((0, _store.getStores)().then(storeResponse => {
      this.stores = storeResponse.data;
    }));
    promises.push((0, _leave.getLeaves)().then(leaveResponse => {
      this.leaves = leaveResponse.data;
    }));
    this.tenants = [this.currentUser.tenant];
    if (!this.$route.params.id) {
      Promise.all(promises).finally(() => {
        this.loading = false;
      });
    }
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
      this.$set(this.rules, 'password', [{
        required: true,
        message: 'Password is required',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Password can not be less than 6 digits',
        trigger: 'blur'
      }]);
      if (!this.currentUser.tenant.uuid && this.user.tenantId) {
        this.user.tenant.uuid = this.user.tenantId;
      } else if (this.currentUser.tenant.uuid && !this.user.tenant.uuid) {
        this.user.tenant.uuid = this.currentUser.tenant.uuid;
      }
      // find max role level
      this.user.maxRoleLevel = 0;
      for (let k = 0; k < this.currentUser.roles.length; k++) {
        if (this.user.maxRoleLevel < this.currentUser.roles[k].level) {
          this.user.maxRoleLevel = this.currentUser.roles[k].level;
        }
      }
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _user.getUser)(this.$route.params.id).then(response => {
        if (response.data) {
          // console.log(response.data)
          this.user = {
            ...response.data,
            roles: response.data.roles.map(role => role.id),
            stores: response.data.stores.map(store => store.uuid),
            leaves: response.data.leaves.map(leave => leave.uuid) /* , tenantId: response.data.tenant ? response.data.tenant.uuid : null */,
            attachmentArrayId: [],
            userRoles: [],
            maxRoleLevel: 0
          };
          if (this.user.hourlyWage > 0 && this.user.monthlySalary > 0) {
            this.hrPerMonth = this.user.monthlySalary / this.user.hourlyWage;
          }
          this.user.attachments.forEach(attachment => {
            let path = attachment.path;
            try {
              path = path.split('/')[1];
            } catch (e) {/* continue regardless of error */
            }
            this.fileList.push({
              id: attachment.id,
              name: path,
              url: attachment.url
            });
          });
          this.uploadData.user_id = this.user.id;

          // match the role name for role checkbox
          for (let i = 0; i < this.user.roles.length; i++) {
            for (let j = 0; j < this.roles.length; j++) {
              if (this.user.roles[i] === this.roles[j].id) {
                this.user.userRoles.push(this.roles[j]);
              }
            }
          }

          // find max role level
          for (let k = 0; k < this.currentUser.roles.length; k++) {
            if (this.user.maxRoleLevel < this.currentUser.roles[k].level) {
              this.user.maxRoleLevel = this.currentUser.roles[k].level;
            }
          }
        }
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.loading = true;
          if (this.$route.params.id) {
            return (0, _user.editUser)(this.user).then(() => {
              (0, _elementUi.Message)({
                message: 'User updated.',
                type: 'success',
                duration: 5 * 1000
              });
              if (this.$workbox && this.$workbox.active instanceof Promise) {
                const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
                return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                  if (value !== uniqueValue) {
                    return (0, _user.getUser)(this.$route.params.id).then(() => {
                      this.$router.push(`/users`);
                    });
                  } else {
                    this.$router.push(`/users`);
                  }
                }, () => {
                  this.$router.push(`/users`);
                });
              } else {
                this.$router.push(`/users`);
              }
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
              this.loading = false;
            });
          } else {
            return (0, _user.createUser)(this.user).then(() => {
              (0, _elementUi.Message)({
                message: 'User created.',
                type: 'success',
                duration: 5 * 1000
              });
              if (this.$workbox && this.$workbox.active instanceof Promise) {
                const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
                return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                  if (value !== uniqueValue) {
                    return (0, _user.getUsers)({
                      page: 1,
                      limit: 10
                    }).then(() => {
                      this.$router.push(`/users`);
                    });
                  } else {
                    this.$router.push(`/users`);
                  }
                }, () => {
                  this.$router.push(`/users`);
                });
              } else {
                this.$router.push(`/users`);
              }
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              this.loading = false;
            });
          }
        }
      });
    },
    /* computeHourlyWage() {
      this.user.hourlyWage = this.hrPerMonth > 0 ? (this.user.monthlySalary / this.hrPerMonth) : 0
    },
    setLeave(id) {
      this.$router.push(`/users/${id}/leaves`)
    }, */

    handlePreview(file) {
      window.open(file.url);
    },
    getStoresStyles(tag) {
      // console.log('getStoresStyles', tag)
      if (tag.uuid) {
        return {
          backgroundColor: '#' + tag.uuid.substr(-6),
          color: 'white'
        };
      }
      if (tag.name) {
        return {
          backgroundColor: '#' + tag.name.substr(-6),
          color: 'white'
        };
      }
    },
    handleRemove(file, fileList) {
      if (file.id !== undefined) {
        (0, _user.removeUserAttachment)(file.id);
      } else if (file.response[0].id !== undefined) {
        (0, _user.removeUserAttachment)(file.response[0].id);
      } else {
        console.error(file);
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Do you confirm remove ${file.name}？`);
    },
    handleSuccess(response) {
      this.user.attachmentArrayId.push(response[0].id);
      if (this.$route.params.id) {
        this.fetchData();
      }
    },
    handleExceed() {
      this.$message.warning('Maximum upload 3 files.');
    },
    checkboxPermission(inputRole) {
      /**
       * cannot select all levels, lower level should stick to lower to avoid manipulation data
       * it cannot functioning properly if you keep crossing the logic
       */
      if (inputRole.name === 'Staff (Full time)' && (this.user.roles.indexOf(9) !== -1 || this.user.roles.indexOf(6) !== -1 || this.user.roles.indexOf(1) !== -1)) {
        // 4
        // level 1
        return false;
      }
      if (inputRole.name === 'Staff (Part time)' && (this.user.roles.indexOf(4) !== -1 || this.user.roles.indexOf(6) !== -1 || this.user.roles.indexOf(1) !== -1)) {
        // 9
        // level 1
        return false;
      }
      if (inputRole.name === 'Store Manager' && this.user.roles.indexOf(6) !== -1 /* || this.user.roles.indexOf(1) !== -1 */) {
        // 5
        // level 2
        return false;
      }
      if (inputRole.name === 'Operation Manager' && (this.user.roles.indexOf(4) !== -1 || this.user.roles.indexOf(9) !== -1 || this.user.roles.indexOf(5) !== -1)) {
        // 6
        // level 3
        return false;
      }
      if (inputRole.name === 'Admin' && (this.user.roles.indexOf(4) !== -1 || this.user.roles.indexOf(9) !== -1)) {
        // 1
        // level 3
        return false;
      }
      if (this.currentUser === null) {
        return false;
      } else if (this.currentUser.roles.find(role => ['Admin'].includes(role.name))) {
        return true;
      } else {
        if (inputRole.name === 'Admin') {
          return false;
        } else {
          return !!(this.user.maxRoleLevel > inputRole.level || this.currentUser.roles.find(role => [inputRole.name].includes(role.name)));
        }
      }
    },
    checkFullTimeRole() {
      let status = false;
      JSON.parse(JSON.stringify(this.user.roles)).forEach(userRoleID => {
        this.roles.forEach(role => {
          if (role.id === userRoleID && role.name === 'Staff (Full time)') {
            status = true;
          }
        });
      });
      return status;
    },
    checkPartTimeRole() {
      let status = false;
      JSON.parse(JSON.stringify(this.user.roles)).forEach(userRoleID => {
        this.roles.forEach(role => {
          if (role.id === userRoleID && role.name === 'Staff (Part time)') {
            status = true;
          }
        });
      });
      return status;
    },
    checkedRole: function () {
      this.leaves.forEach(user_leave => {
        user_leave.roles.forEach(role => {
          if (JSON.parse(JSON.stringify(this.user.roles)).includes(role.id)) {
            if (!JSON.parse(JSON.stringify(this.user.leaves)).includes(user_leave.id)) {
              this.user.leaves.push(user_leave.uuid);
            }
          }
        });
      });
      this.checkFullTimeRole();
      this.checkPartTimeRole();
    }
  }
};
exports.default = _default;