"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _errorParse = require("@/utils/error-parse");
var _store = require("@/api/store");
var _elementUi = require("element-ui");
var _pluralize = _interopRequireDefault(require("pluralize"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  /* components: {
    Pagination
  }, */
  data() {
    return {
      storeId: this.$route.params.id,
      storeProductId: this.$route.params.sProdId,
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 9999
      },
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _store.getStoreProductCombos)({
        storeProductId: this.storeProductId
      }).then(response => {
        this.list = response.data;
        this.total = response.data ? response.data.length : 0;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },
    deleteStoreProductCombo(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete store product Combo?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        return (0, _store.deleteStoreProductCombo)(id).then(() => {
          (0, _elementUi.Message)({
            message: 'Store Product Combo deleted',
            type: 'success',
            duration: 5 * 1000
          });
          this.fetchData();
        }).catch(err => (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        }));
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    getSummaries() {
      const summary = {};
      let totalPercentagePrice = 0;
      if (this.list) {
        this.list.forEach(row => {
          if (row.rawMaterial) {
            totalPercentagePrice += row.rawMaterial.defaultPrice * row.percentage / 100;
          }
        });
      }
      summary[5] = '總成本: $' + totalPercentagePrice.toFixed(4);
      return summary;
    }
  }
};
exports.default = _default;