"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _store = require("@/api/store");
var _report = require("@/api/report");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import moment from 'moment'
var _default = {
  data() {
    return {
      loading: false,
      salary: {
        fromTime: '',
        toTime: '',
        stores: []
      },
      rules: {
        dateRange: [{
          type: 'array',
          required: true,
          trigger: 'change',
          validator: (rule, value, cb) => {
            try {
              if (this.dateRange[0] === '' || this.dateRange[1] === '') {
                cb(new Error('請墳寫日期'));
              } else {
                cb(undefined); // success
              }
            } catch (e) {
              cb(new Error('請墳寫日期'));
            }
          }
        }],
        stores: [{
          type: 'array',
          required: true,
          trigger: 'click',
          validator: (rule, value, cb) => {
            try {
              if (this.salary.stores.length === 0) {
                cb(new Error('Please select Store'));
              } else {
                cb(undefined); // success
              }
            } catch (e) {
              cb(new Error('Please select Store'));
            }
          }
        }]
      },
      allStoreChecked: false,
      stores: []
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    dateRange: {
      get() {
        return [this.salary.fromTime, this.salary.toTime];
      },
      set(range) {
        if (range != null) {
          this.salary.fromTime = range[0];
          this.salary.toTime = range[1];
        } else {
          this.salary.fromTime = '';
          this.salary.toTime = '';
        }
      }
    }
  },
  watch: {
    allStoreChecked() {
      if (this.allStoreChecked) {
        this.salary.stores = [];
        this.stores.forEach(store => {
          this.salary.stores.push(store.uuid);
        });
      } else {
        this.salary.stores = [];
      }
    }
  },
  async created() {
    (0, _store.getStores)().then(storeResponse => {
      this.stores = storeResponse.data;
    });
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          (0, _report.exportStoresSalary)(this.salary).then(response => {
            // console.log(response)
            const fileURL = window.URL.createObjectURL(response);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `salary.pdf`);
            document.body.appendChild(fileLink);
            fileLink.click();
          }).catch(err => {
            console.error(err);
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
              type: 'error',
              duration: 5 * 1000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;