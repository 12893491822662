"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _store = require("@/api/store");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  data() {
    return {
      uuid: this.$route.params.id,
      list: null,
      listLoading: false,
      listQuery: {
        fromTime: null,
        toTime: null,
        groupBy: 'date',
        page: 1,
        limit: 9999
      },
      groupByOptions: [{
        key: 'daily_session',
        name: 'Session'
      }, {
        key: 'hour',
        name: 'Hour'
      }, {
        key: 'date',
        name: 'Day'
      }, {
        key: 'week',
        name: 'Week'
      }, {
        key: 'month',
        name: 'Month'
      }, {
        key: 'year',
        name: 'Year'
      }],
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    const today = new Date();
    this.listQuery['fromTime'] = new Date(today.getFullYear(), today.getMonth(), 1);
    this.listQuery['toTime'] = new Date(today.getFullYear(), today.getMonth() + 1, -1);
    this.listQuery['toTime'].setDate(this.listQuery.toTime.getDate() + 1);
    this.fetchData();
  },
  methods: {
    fetchData() {
      const param = {};
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          const current_datetime = this.listQuery[key];
          param[key] = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds();
        } else {
          param[key] = this.listQuery[key];
        }
      }
      this.listLoading = true;
      (0, _store.getStoreSales)(this.$route.params.id, {
        ...param
      }).then(response => {
        this.list = response.data;
        this.total = response.data ? response.data.length : 0;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    getSummaries() {
      const summary = {};
      let totalActualSale = 0;
      let totalEstimatedSale = 0;
      if (this.list) {
        this.list.forEach(row => {
          totalActualSale += row.actualSale;
          totalEstimatedSale += row.estimatedSale;
        });
      }
      summary[2] = _i18n.default.t('Order.Total') + ': $' + totalActualSale.toFixed(0);
      summary[3] = _i18n.default.t('Order.Total') + ': $' + totalEstimatedSale.toFixed(0);
      return summary;
    }
  }
};
exports.default = _default;