var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--default",
              attrs: { to: "/stores" },
            },
            [_vm._v(_vm._s(_vm.$t("StoreExpense.Back")))]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--default",
              class: { "is-disabled": _vm.isOffline },
              attrs: {
                to: _vm.isOnline
                  ? "/stores/" + _vm.storeId + "/expenses/add"
                  : "#",
              },
            },
            [_vm._v(_vm._s(_vm.$t("StoreExpense.AddStoreExpense")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreExpense.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.store.name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreExpense.Expense") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.expense.name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.user.roles.find(function (role) {
            return ["Admin"].includes(role.name)
          }) &&
          (_vm.user.name === "Wilson Lau" || _vm.user.name === "Waiman")
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("StoreExpense.Amount") },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        $" +
                              _vm._s(scope.row.amount) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1211790037
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreExpense.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--warning",
                            attrs: {
                              to:
                                "/stores/" +
                                _vm.storeId +
                                "/expenses/edit/" +
                                scope.row.uuid,
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _vm._v(" " + _vm._s(_vm.$t("StoreExpense.Edit"))),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.isOffline,
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteStoreExpense(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("StoreExpense.Delete")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }