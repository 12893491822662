"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _elementUi = require("element-ui");
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { parseError } from '@/utils/error-parse'
var _default = {
  data() {
    return {
      loading: false,
      store: {
        id: null,
        name: ''
      },
      estimatedUploadErrors: [],
      actualUploadErrors: [],
      uploadDomain: process.env.VUE_APP_BASE_API,
      uploadHeader: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      buttonText: 'Add',
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        }
      },
      isEdit: false,
      importDate: '',
      actualPrice: 'total_tendered'
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
    }
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    this.importDate = new Date(year, month, day);
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _store.getStore)(this.$route.params.id).then(response => {
        this.store = response.data;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    onUploadSuccess() {
      this.uploadErrors = [];
      this.$refs.PreviewUpload.clearFiles();
      this.$router.push(`/stores/${this.$route.params.id}/sales`);
    },
    onUploadError(error) {
      error = JSON.parse(error.message);
      (0, _elementUi.Message)({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      });
      this.actualUploadErrors = error.data;
      this.$refs.PreviewUpload.clearFiles();
    },
    onActualUploadSuccess() {
      this.uploadErrors = [];
      this.$refs.actualPreviewUpload.clearFiles();
      this.$router.push(`/stores/${this.$route.params.id}/sales`);
    },
    onActualUploadError(error) {
      error = JSON.parse(error.message);
      (0, _elementUi.Message)({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      });
      // Message({
      //   message: parseError(error) || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      this.actualUploadErrors = error.data;
      this.$refs.actualPreviewUpload.clearFiles();
    },
    onEstimatedUploadSuccess() {
      this.estimatedUploadErrors = [];
      this.$refs.estimatedPreviewUpload.clearFiles();
      this.$router.push(`/stores/${this.$route.params.id}/sales`);
    },
    onEstimatedUploadError(error) {
      error = JSON.parse(error.message);
      (0, _elementUi.Message)({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      });
      // Message({
      //   message: parseError(error) || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      this.uploadErrors = error.data;
      this.$refs.estimatedPreviewUpload.clearFiles();
    },
    onCancel() {
      this.$router.push(`/stores/${this.$route.params.id}/sales`);
    },
    formatDate(date) {
      date = new Date(date);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    }
  }
};
exports.default = _default;