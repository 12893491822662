"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _errorParse = require("@/utils/error-parse");
var _shift = require("@/api/shift");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    } /* ,
      ...mapGetters([
       'name'
      ]) */
  },

  async created() {
    this.fetchData();
  },
  methods: {
    displayFromTime(data) {
      data.fromTime = '' + data.from_hour + ':' + (data.from_min > 9 ? '' : '0') + data.from_min;
      return data.fromTime;
    },
    displayToTime(data) {
      data.toTime = '' + data.to_hour + ':' + (data.to_min > 9 ? '' : '0') + data.to_min;
      return data.toTime;
    },
    fetchData() {
      this.listLoading = true;
      (0, _shift.getShifts)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response?.meta?.total ?? 10; // @TODO check availibility
        this.list.forEach((data, index) => {
          data.id = index + response.meta.from;
        });
      }).finally(() => {
        this.listLoading = false;
      });
    },
    deleteShift(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete shift?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _shift.deleteShift)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Shift deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }
  }
};
exports.default = _default;