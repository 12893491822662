"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _leave = require("@/api/leave");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        perPage: 10
      },
      total: 0,
      leaves: []
    };
  },
  async created() {
    await (0, _leave.getLeaves)(this.listQuery).then(response => {
      var data = response.data;
      this.leaves = [];
      for (let i = 0; i < data.length; i++) {
        data[i].selected = false;
        this.leaves.push(data[i]);
      }
    });
    await (0, _leave.getUserLeave)(this.$route.params.id).then(response => {
      var data = response.data;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.leaves.length; j++) {
          if (this.leaves[j].id === data[i].leave_id) {
            this.leaves[j].selected = true;
          }
        }
      }
    });
  },
  methods: {
    selectLeave(leave) {
      leave.selected = !leave.selected;
    },
    submit() {
      (0, _leave.setUserLeave)({
        user: this.$route.params.id,
        leaves: this.leaves
      }).then(response => {
        if (response.status === 'Y') {
          alert('Update Success');
        }
      });
    }
  }
};
exports.default = _default;