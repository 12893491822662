"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _errorParse = require("@/utils/error-parse");
var _store = require("@/api/store");
var _moment = _interopRequireDefault(require("moment"));
var _rosterConfirmation = require("@/api/rosterConfirmation");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        perPage: 999
      },
      rosterConfirmations: [],
      total: 0
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // this.listLoading = true
      (0, _store.getStores)({
        ...this.listQuery,
        userId: this.user.uuid,
        tenant_id: this.user.tenant.uuid
      }).then(response => {
        this.list = Object.freeze(response.data.map((store, index) => ({
          uuid: store.uuid,
          name: store.name,
          authCode: store.authCode,
          id: index + 1
        })));
        /* if (response.meta !== undefined && response.meta.total !== undefined) {
          this.total = response.meta.total
        } */
      }).finally(() => {
        this.listLoading = false;
      });
      (0, _rosterConfirmation.getRosterConfirmations)({
        fromTime: (0, _moment.default)().format('YYYY-MM-DD'),
        toTime: (0, _moment.default)().format('YYYY-MM-DD'),
        pending_approval: 'true'
      }).then(response => {
        this.rosterConfirmations = response.data;
      });
    },
    displayFromTime(data) {
      data.fromTime = '' + data.from_hour + ':' + (data.from_min > 9 ? '' : '0') + data.from_min;
      return data.fromTime;
    },
    displayToTime(data) {
      data.toTime = '' + data.to_hour + ':' + (data.to_min > 9 ? '' : '0') + data.to_min;
      return data.toTime;
    },
    showLocationCode(code) {
      (0, _elementUi.Message)({
        message: 'Location Code: ' + code,
        type: 'success',
        duration: 5 * 1000
      });
    },
    deleteStore(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete store?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _store.deleteStore)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Store deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    } /* ,
      changeOnline(store) {
       if (store.isOnline) {
         onlineStore(store.uuid).then(response => {
           console.log(response)
         })
       } else {
         offlineStore(store.uuid).then(response => {
           console.log(response)
         })
       }
      } */
  }
};
exports.default = _default;